var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "32",
        height: "32",
        viewBox: "0 0 32 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
      on: { click: _vm.handleClick },
    },
    [
      _c("rect", {
        attrs: {
          x: "0.25",
          y: "0.25",
          width: "31.5",
          height: "31.5",
          rx: "7.75",
          fill: "#FEF0F0",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10 12H11.3333H22",
          stroke: "#DB6D39",
          "stroke-width": "1.4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13.3334 12.0002V10.6668C13.3334 10.3132 13.4739 9.97407 13.7239 9.72402C13.9739 9.47397 14.3131 9.3335 14.6667 9.3335H17.3334C17.687 9.3335 18.0261 9.47397 18.2762 9.72402C18.5262 9.97407 18.6667 10.3132 18.6667 10.6668V12.0002M20.6667 12.0002V21.3335C20.6667 21.6871 20.5262 22.0263 20.2762 22.2763C20.0261 22.5264 19.687 22.6668 19.3334 22.6668H12.6667C12.3131 22.6668 11.9739 22.5264 11.7239 22.2763C11.4738 22.0263 11.3334 21.6871 11.3334 21.3335V12.0002H20.6667Z",
          stroke: "#DB6D39",
          "stroke-width": "1.4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.6666 15.3335V19.3335",
          stroke: "#DB6D39",
          "stroke-width": "1.4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17.3334 15.3335V19.3335",
          stroke: "#DB6D39",
          "stroke-width": "1.4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("rect", {
        attrs: {
          x: "0.25",
          y: "0.25",
          width: "31.5",
          height: "31.5",
          rx: "7.75",
          stroke: "#FDE2E2",
          "stroke-width": "0.5",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
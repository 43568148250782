<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="handleClick"
  >
    <rect
      x="0.25"
      y="0.25"
      width="31.5"
      height="31.5"
      rx="7.75"
      fill="#F2F6FC"
    />
    <g clip-path="url(#clip0_24105_40614)">
      <path
        d="M15.3334 10.6665H10.6667C10.3131 10.6665 9.97395 10.807 9.7239 11.057C9.47385 11.3071 9.33337 11.6462 9.33337 11.9998V21.3332C9.33337 21.6868 9.47385 22.0259 9.7239 22.276C9.97395 22.526 10.3131 22.6665 10.6667 22.6665H20C20.3537 22.6665 20.6928 22.526 20.9428 22.276C21.1929 22.0259 21.3334 21.6868 21.3334 21.3332V16.6665"
        stroke="#4CA7D8"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.3334 9.66665C20.5986 9.40144 20.9583 9.25244 21.3334 9.25244C21.7084 9.25244 22.0682 9.40144 22.3334 9.66665C22.5986 9.93187 22.7476 10.2916 22.7476 10.6667C22.7476 11.0417 22.5986 11.4014 22.3334 11.6667L16 18L13.3334 18.6667L14 16L20.3334 9.66665Z"
        stroke="#4CA7D8"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <rect
      x="0.25"
      y="0.25"
      width="31.5"
      height="31.5"
      rx="7.75"
      stroke="#D9ECFF"
      stroke-width="0.5"
    />
    <defs>
      <clipPath id="clip0_24105_40614">
        <rect width="16" height="16" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconTrashMobile',
  methods: {
    handleClick(event) {
      this.$emit('onClick', event)
    },
  },
}
</script>

<style scoped></style>
